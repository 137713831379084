/**
 * Header overrides for STWC
 */

// override the logo colour
header#new-homepage-test-header,
header#simple-header {
	div.logo {
		top: 4px;
		margin-left: -44px;
		.svg-logo {
			path,
			polygon,
			rect {
				fill: $anthracite;
			}

			line {
				stroke: $anthracite;
				stroke-width: 1.6975;
				stroke-miterlimit: 3.8637;
			}
		}

		.svg-wine-club {
			path,
			polygon,
			rect {
				fill: $rioja;
			}
		}

		@include media-breakpoint-down(sm) {
			a {
				background: url("/images/uk/en/homepage/logos/stwc-logo.svg");
				background-repeat: no-repeat;
				background-size: 86px 50px;
				width: 100px;
				height: 50px;
				.stw-logo-img {
                    display: none;
                }
			}
		}

		@include media-breakpoint-up(md) {
			a {
				width: 202px;
			}
			margin-left: -101px;
		}
	}

	&.is-sticky {
		div.logo {
			top: 4px;
			margin-left: -44px;
			width: 100px;
			height: 50px;
			@include media-breakpoint-down(sm) {
				a {
					background: url("/images/uk/en/homepage/logos/stwc-logo.svg");
					background-repeat: no-repeat;
					background-size: 86px 50px;
					width: 100px;
					height: 50px;
				}
			}
		}
	}

	&.expanded {
		div.logo {
			top: 4px;
			margin-left: -44px;
			@include media-breakpoint-down(sm) {
				a {
					background: url("/images/uk/en/homepage/logos/stwc-logo.svg");
					background-repeat: no-repeat;
					background-size: 86px 50px;
					width: 100px;
					height: 50px;
				}
			}
		}
	}

	#main-navigation {
		ul {
			li {
				&.nav-top-level > .submenu a {
					font-size: 1.1em;
					letter-spacing: 0em;
				}
				&.flipper-container .back-panel {
					left: 19px;
					.logo {
						background: url("/images/uk/en/homepage/logos/stwc-logo-square.svg");
						height: 47px;
						width: 72px;
						background-position: 0 0;
						background-repeat: no-repeat;
					}
				}
			}
		}
	}
}
