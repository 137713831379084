$color-options: (
    orange: #d07d17,
    green: #9bab4d,
    pink: #df897c,
    red: $red,
    black: #000,
    blue: $blue,
    teal: #409090,
    sage: $leaf-green,
    gold: #a89054,
);

.big-strip {
    background-color: $blue;
    font-family: $font-family-playfair-display;
}

header#new-homepage-test-header {
    div.logo {
        polygon,
        path,
        rect {
            fill: $black;
        }
    }
    #cart {
        span.number-of-items {
            background: $purple;
        }
    }
    .masthead {
        .cart{
            svg {
                path {
                    fill: none;
                }
            }
        }
        
        #account{
            svg {
                path {
                    fill: none;
                }
            }
        }
    }
    &.is-sticky {
        .masthead {
            #account, #cart {
                .number-of-items {
                    background: $purple;
                }
                svg {
                    path {
                        fill: none;
                    }
                }
            }
        }
    }
    #main-navigation {
        ul {
            li {
                .nav-home svg path {
                    fill: none;
                    stroke: $purple;
                }
                &.flipper-container:hover a {
							path {
								fill: $purple;
							}
                }
                .nav-item {
                    color: $black;                    
                    i {
                        border-color: $purple;
                    }
                }
                &.open-nav>.nav-item {
                    background: $purple;
                    a {
                        color: $white;
                    }
                }
                &.open-nav>.submenu>ul>li.open-nav>.nav-item {
                    background: $purple;
                }
            }
        }
        &.nav-intended {
            ul {
                li {
                    .submenu {
                        background: $off-white; // Used for nav dropdown
                        border: none;
                        border-image: none;

                        &::before {
                            content: "";
                            height: 1px;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: -1px;
                            background: $purple;
                        }

                        a {
                            // font-family: $font-family-playfair-display;
                            &:hover {
                                color: $purple;
                            }
                            strong {
                                color: $purple;
                                @include media-breakpoint-down(sm){
                                    color: #000000;
                                }
                            }
                            &.highlight {
                                color: $purple;
                                span.fa-chevron-right {
                                    color: $purple;
                                }
                                &.bold {
                                    font-weight: 600;
                                }
                            }
                        }
                        ul.linkmenu {
                            .nav-item {
                                @include media-breakpoint-up(sm) {
                                color: $purple !important;
                                font-family: $font-family-pt-sans;
                                text-transform: uppercase;
                                font-weight: 400;
                                }
                            }
                        }
                        @media (max-width:767px) {
                            .nav-item {
                                a {
                                    &:hover {
                                        color: inherit;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.expanded {
            nav {
                background: $off-white;
            }
        }
        nav {
            font-family: $font-family-pt-sans;
        }
    }
}

@include media-breakpoint-down(sm) {
    header#new-homepage-test-header,
    header#simple-header {
        background-color: $white;
        height: 57px;
        padding: 18px 8px 8px 8px;

        .header-line {
            top: 57px;
        }
        &.expanded {
            height: 57px;
            .logo a {
                height: 57px;
                width: 57px;
            }
        }

        @include media-breakpoint-down(sm) {
            div.logo {
                top: 4px;
                margin-left: -34px;
                a {
                    height: 41px;
                    width: 72px;
                }
            }
        }

        &.is-sticky {

            @include media-breakpoint-down(sm) {
                div.logo {
                    top: 9px;
                    height: 41px;
                    width: 72px;
                    margin-left: -34px;
                    a {
                        height: 41px;
                        width: 72px;
                    }
                }
            }

            &.is-sticky {
                height: 57px;
            }
        }
    }
}

@media (min-width: 992px) { 
    header#new-homepage-test-header {
        #main-navigation {
            ul {
                li {
                    &.nav-top-level {
                        &:hover {
                            border-color: $purple;
                            color: $purple;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:767px) {
    header#new-homepage-test-header {
        #main-navigation {
            ul {
                li {
                    &.nav-top-level > .nav-item,
                    &.nav-login-item > .login-link {
                        // SVG icon before the menu for stwc
                        &:before {
                            background: url("/images/uk/en/stw/homepage/icons/menu-home.svg");
                            background-position: 0 0;
                            background-repeat: no-repeat;
                        }
                        &.nav-wines::before {
                            background-image: url("/images/uk/en/stw/homepage/icons/menu-wine.svg");
                        }
                        &.nav-mixed-cases::before {
                            background-image: url("/images/uk/en/stw/homepage/icons/menu-mixed.svg");
                        }
                        &.nav-wine-clubs::before {
                            background-image: url("/images/uk/en/stw/homepage/icons/menu-wineclubs.svg");
                        }
                        &.nav-event-services::before {
                            background-image: url("/images/uk/en/stw/homepage/icons/menu-events.svg");
                        }
                        &.nav-gifts::before {
                            background-image: url("/images/uk/en/stw/homepage/icons/menu-gifts.svg");
                        }
                        &.nav-advice::before {
                            background-image: url("/images/uk/en/stw/homepage/icons/menu-advice.svg");
                        }
                        &.nav-offers::before {
                            background-image: url("/images/uk/en/stw/homepage/icons/menu-offers.svg");
                        }
                        &.nav-customer-service::before {
                            background-image: url("/images/uk/en/stw/homepage/icons/menu-customer-service.svg");
                        }
                        &.login-link::before,
                        &#mobile-loggedin::before {
                            background-image: url("/images/uk/en/stw/homepage/icons/menu-account.svg");
                        }
                }
                }
            }
            .telephone {
                &::before {
                    background: none;
                }
            }
        }
    }

    .mobilenavBarcodescanner .nav-barcodescanner::before {
        background: url("/images/uk/en/stw/homepage/icons/menu-bottle-scanner.svg") center center no-repeat !important;
    }
    .mobilenavChatwithvinny .nav-chatwithvinny::before {
        background: url("/images/uk/en/stw/homepage/icons/menu-vinnychat.svg") center center no-repeat !important;
    }
}

@media (min-width: 768px) {
    header#new-homepage-test-header {
        div.logo {
            top: 21px!important;
            a {
                height: 90px;
                width: 202px;
            }
            svg {
                height: 90px;
                width: 202px;
            }
        }
        .masthead {
            svg {
                path {
                    fill: $purple;
                }
            }
            #top-links {
                flex-direction: row-reverse;
            }
        }
        #main-navigation {
            ul {
                li {
                    &.nav-top-level {
                        font-family: $font-family-pt-sans;
                        text-transform: uppercase;
                        letter-spacing: 0.7px;
                        font-size: 0.85em;
                        // border-bottom-color: $dark-cream;
                        &:hover, &.selected {

                            a {
                                &:first-of-type {
                                    background: transparent;
                                }
                            }
                            & > .submenu {
                                background: $off-white; // Used for nav dropdown
                                border: none;
                                border-image: none;
                                li {
                                    .nav-item {
                                        color: $purple;
                                        text-transform: uppercase;
                                        font-family: $font-family-pt-sans;
                                    }
                                    a {
                                        font-family: $font-family-sans-serif;
                                        text-transform: none;
                                    }
                                }
                            }
                        }
                        &.selected {
                            border-color: $purple;
                            color: $purple;
                        }
                        .nav-item a {
                                padding: 0px 8px;
                            }
                    }
                    &.flipper-container {
                        height: 20px;
                    }
                }
            }
            // border-top: 1px solid $red !important;
        }
        &.is-sticky {
            .desktop-search-panel {
                .open {
                    background-color: $purple;
                }
            }
        }
    }
}

// Search
.desktop-search-panel {
    button[type="submit"] {
        @media (min-width: 768px){
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:hover  {
            background: lighten($purple, 10%);
        }
    }
    input[type="text"]{
        @include media-breakpoint-up(lg){
            width: 223px;
        }
        font-style: normal;
    }
    &.focus{
        button[type="submit"] {
            background: $purple;
            height: 44px;
            width: 44px;
        &:hover  {
            background: lighten($purple, 10%) !important;
        }
        }
    }
}

section.guarantee {
    background: $teal;
    color: $white;
    svg {
        path, tspan {
            fill: none;
            stroke: $white;
        }
    }
    a {
        color: $white;
    }
    .bootstrap-4 {
        background: $teal;
    }
    .trustpilot {
        svg {
            height: 51px;
        }
    }
}

// Footer
footer {
    section {
        &.footer-links {
            background: #333333;
            .nav-item h4 {
                text-transform: uppercase;
                @media (min-width: 768px) {
                    font-family: $font-family-pt-sans;
                }
            }
            .contact-details {
                .contact h4,
                .phone span {
                    font-weight: 600;
                    @media (min-width: 768px) {
                        font-family: $font-family-pt-sans;
                    }
                }
            }
        }
        &.footer-copyright {
            background: $black;
            .logo {
                margin: 5px 0;
            }
            .copy {
                a { 
                    color: inherit !important;
                }
            }
        }
        &.social-links {
            background: $light-orange;
            text-transform: uppercase;
            svg {
                &.icon-social-facebook,
                &.icon-social-twitter,
                &.icon-social-instagram,
                &.icon-social-youtube {
                    .cls-1 {
                        fill: $white;
                    }
                    .cls-2 {
                        fill: $teal;
                    }
                }
                &.icon-social-blog {
                    .cls-white {
                        fill: $white;
                    }
                    .cls-teal {
                        fill: $teal;
                    } 
                }
            }
            .follow {
                font-family: $font-family-pt-sans;
                font-size: 3rem;
                font-weight: 600;
            }
            ul {
				li {
					height: 40px;
                }
            }
        }
    }
}

section.quick-links {
    a.btn {
        color: #3c3c3c;
        border-color: $recommended-cta;
        font-family: $font-family-pt-sans;
        text-transform: uppercase;
        font-size: 2rem;
        border-radius: 5px !important;
        font-weight: 400;
        &:hover,
        &:focus,
        &:active {
            background-color: $recommended-cta;
            color: white;
        }
        .arrow.right { 
            border-color: $black;
            border-width: 0rem 0.06rem 0.06rem 0rem;
            top: -2px;
            @include media-breakpoint-only(md) {
				top:-1px;
			}
            @include media-breakpoint-down(xs) {
                top: 41%
            }
        }
    }
}

section.recently-viewed {
    & > div.container {
        min-height: 200px;
    }
    @include media-breakpoint-up(md) {
        .scroll {
            &.left,
            &.right {
                border-color: $black;
            }
        }
    }
}

section.media-content {
    h4 {
        font-family: $font-family-pt-sans;
        text-transform: uppercase;
    }
    // generate all the colour variables
    div[class^="block-"] {
        .text {
            h4 {
                font-weight: 600;
            }
        }
        @each $key, $val in $color-options {
            .text.#{$key} {
                background-color: #{$val};
            }
            .btn.#{$key} {
                background-color: #{$val};
                border-color: $white;
                &:hover {
                    background: rgba(0,0,0,0.4);
                }
            }
        }
    }
}

section.recommended {
    background: $dark-cream;
    color: $black;
    & > div.container {
        min-height: 400px;
    }
    .arrow {
        &.right {
            border-color: $black;
        }
    }
    .scroll.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    .view-all {
        a {
            color: $black;
        }
    }
    @include media-breakpoint-up(md) {
        .scroll {
            &.left,
            &.right {
                border-color: $black;
            }
        }
    }
    .window {
        @include media-breakpoint-up(md) {
            border-color: $dark-cream;
        }
        li a {
            .button {
                background: $leaf-green;
                color: $white;
            }
            .name {
                color: $black;
            }
            .image {
                background: none;
            }
            .rating {
                i.fa-star {
                    color: #c28926;
                }
            }
        }
    }
    h2 {
        font-size: 28px;
        @media screen and (max-width:480px) {
            font-size: 20px;
        }
    }
}

.hero {
    .hero-panel {
        @include media-breakpoint-up(md) {
            padding: 0;
            a {
                margin: 0;
                border-radius: 0px;
            }
        }
        img {
            @include media-breakpoint-up(md) {
                height: 320px;
            }
            @include media-breakpoint-up(lg) {
                height: 432px;
            }
        }
    }
    .tab {
        @include media-breakpoint-up(md) {
            &::before {
                left: -26px;
            }
        }
    }
}

.delivery-option-panel {
    .color-red {
        fill: #7A0040;
    }
    .template {
        h3 {
            padding-bottom: 15px;
            font-size: 22px;
            font-family: $font-family-pt-sans;
        }
    }
}
section.recommended,
section.recently-viewed {
    .scroll {
        border: solid $black;
        border-width: 0 1px 1px 0;
    }
}
@media (max-width: 768px) {
    section.recommended,
    section.recently-viewed {
        .window {
            ul {
               transition: transform .5s ease-in-out;
            }
            li{
                margin: 15px 10px 0 10px;
                @media (max-width: 350px) { 
                    margin-right: 8px;
                    margin-left: 8px;
                }
            }
        }
        .scroll {
            border: solid $black;
            border-width: 0 1px 1px 0;
            display: block;
            position: absolute;
            top: 62%;
            margin-top: -5px;
            padding: 10px;
            z-index: 1;
            cursor: pointer;
            opacity: 1;

            &.left{
                left: 35%;
                -webkit-transform: rotate(-225deg);
                transform: rotate(-225deg);
            }
            &.right{
                right: 35%;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }

            &.left, &.right {
                border-color: $black;
            }
        }
    }
    section.recommended {
        .scroll {
            top: auto;
            bottom: 12.2%;
            padding: 9px;
            margin-top: -5px;
        }
        .slider-dots {
            text-align: center;
            padding:0 3rem;
            margin: 3rem 0px;
            span {
                display: inline-block;
                margin: 0 0.2rem;
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 50%;
                border: 0.1rem solid #487973;
                cursor: pointer;
                vertical-align: middle;
                &.active {
                    background-color: $black;
                    border-color: transparent;
                }
            }
        }
    }
}
section.recommended{
    .scroll {
        @media (max-width: 500px) { 
            &.left{
                left: 30%;
            }
            &.right{
                right: 30%;
            }
        }
        @media (max-width: 380px) { 
            bottom: 12.85%;
            &.left{
                left: 25%;
            }
            &.right{
                right: 25%;
            }
        }
        @media (max-width: 335px) {
            bottom: 12.25%;
        } 
    }
}

//Upsell
.signup-button-container {
    background: $dark-cream;
}

// VP LP
.stw.cms {
    .info-section-title {
        font-family: "PT Sans", "sans-serif" !important;
    }
    .reference-card-section h2 {
        font-family: "PT Sans", "sans-serif" !important;
    }
    .info-block-title {
        color: #1A7C7B !important;
        font-family: $font-family-pt-sans !important;
    }
    .reference-card-button {
        color: $white !important;
    }
}

section.newsletter {
    padding-top: 2.5rem;
    h2, i {
        font-style: normal !important;
        font-family: $font-family-pt-sans !important;
    }
}

.stw section.media-content h2 {
    font-family: $font-family-pt-sans !important;
    @media screen and (max-width:480px) {
        font-size: 20px;
    }
}

//Beyond the label Overlay
#tasting-note-overlay,#print-tasting-note{
    .buythiswine{
        svg{
            color: #1A7C7B;
            fill: #1A7C7B;
        }
        border: 1px solid #1A7C7B;
    }
}
   