#main-navigation {
    .social-links {
        .submenu {
            li:nth-of-type(2),
            li:nth-of-type(3),
            li:nth-of-type(4) {
                display: none !important;
            }
        }
    }
    .gifts{
        .nav-gifts {
            & > a {
                color: #a88f54 !important;
            }
        }
        &:hover {
            border-color: #a88f54 !important;
            & > .submenu {
                border-image: linear-gradient(135deg,
                darken(saturate(adjust-hue(#a88f54, 6), 2.75), 8.63),#a88f54)  !important;
            }
        }
    }
    .christmasnav.offers {
        .nav-offers {
            & > a {
                color: #bb532d !important;
            }
        }
        &:hover {
            border-color: #bb532d !important;
        }
    }
}